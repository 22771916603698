'format es6';
'use strict';

export default {
	init() {
		let isPdfInline = document.querySelector('[data-pdf]');

		if (isPdfInline) {
			let pdfContainer = document.querySelector('[data-pdf-container]');
			let containerWidth = pdfContainer.getBoundingClientRect().width;
			let containerHeight = Math.round(containerWidth * 1.35) + "px";
			pdfContainer.style.height = containerHeight;
		}
	}
};
